import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Paragraph, Row} from "../components/atomics"
import colors from "../colors"
import { useLanguage } from "../hooks"

const text = {
  title: {
    en: 'Terms of use',
    pt: 'Termos de uso'
  },
  s1: {
    en: 'Overview',
    pt: 'Visão Geral'
  },
  's1.p1': {
    en: 'Flynow is a tool that aims to help people improve their productivity, by managing tasks, habits and goals. Flynow is available for cell phones whose operating system is Android or iOS, through their official stores. In addition, Flynow is available for computers through the Web version.',
    pt: 'Flynow é um ferramenta que visa ajudar as pessoas melhorar sua produtividade, através do gerenciamento de tarefas, hábitos e metas. O Flynow está disponível para celulares cujo sistema operacional é Android ou iOS, atravéz das lojas oficiais das mesmas. Além disso o Flynow está disponível para computador atravéz da versão Web.',
  },
  's1.p2': {
    en: 'Flynow uses the best methods for managing tasks, habits and goals. For time / task management the application uses the Time Triad method, for habit management, the application uses the habit loop. Finally, to perform goal management, the application uses the SMART method. In addition, it uses gamification to encourage its users to carry out their activities. It also uses a variety of statistics to provide feedback to the user regarding their performance.',
    pt: 'O Flynow utiliza os melhores métodos para gestão de tarefas, hábitos e metas. Para o gerenciamento de tempo/tarefas o aplicativo utiliza o método da Tríade do Tempo, para o gerenciamento de hábitos, o aplicativo utiliza o loop do hábito. Por fim, para realizar a gestão de metas, o aplicativo utiliza o método SMART. Além disso, utiliza gamificação para estimular seus os usuários na realização de suas atividades. E também utiliza diversas estatísticas para fornecer um feedback para o usuário com relação ao seu desempenho.'
  },
  s2: {
    en: 'Download the Flynow App',
    pt: 'Download do App Flynow'
  },
  's2.p1': {
    en: 'In order for the user to be able to download the application on his mobile device, he must make sure to download it from the official Android and iOS stores, that is, Play Store and App Store. The application is not officially distributed in other stores.',
    pt: 'Para que o usuário possa baixar o aplicativo em seu aparelho celular, deverá se certificar de fazer o Download nas Lojas oficiais do Android e iOS, ou seja, Play Store e App Store. O aplicativo não é distribuído de forma oficial em outras lojas.'
  },
  s3: {
    en: 'Free and Premium Version',
    pt: 'Versão Grátis e Premium'
  },
  's3.p1': {
    en: 'There will be no cost for the use and download of the Flynow App, nor for any updates.',
    pt: 'Para a utilização e download do App Flynow não haverá custo nenhum, tampouco para eventuais atualizações.'
  },
  's3.p2': {
    en: 'For the Premium version there will be a recurring charge to the user who wants to use it, according to the plan that the user decides to hire. It is not mandatory to use the Premium version, leaving the user with the decision to obtain it for their mobile device.',
    pt: 'Para a versão Premium haverá uma cobrança recorrente ao usuário que queira utilizá-la, conforme o plano que o mesmo decidir contratar. Não é obrigatória a utilização da versão Premium, cabendo ao usuário a decisão de obter-lá para seu dispositivo móvel. \nO usuário possui acesso as funcionalidades premium enquanto tiver com a sua assinatura ativa, ou seja, quando a assinatura expirar, todos os benefícios da versão premium são removidos automaticamente!'
  },
  s4: {
    en: 'Registration and Use',
    pt: 'Cadastro e Uso'
  },
  's4.p1': {
    en: 'The User authorizes Flynow to use, for an indefinite period, the information provided at the time of registration and during the use of the application, for statistical purposes and sending advertising material, newsletters, reports, etc.',
    pt: 'O Usuário autoriza o Flynow a utilizar, por prazo indeterminado, as informações fornecidas no ato do cadastro e durante o uso do aplicativo, para fins estatísticos e envio de material publicitário, newsletters, informes, etc .'
  },
  's4.p2': {
    en: "It is not the responsibility of Flynow if a User uses another user's registration.",
    pt: 'Não é de responsabilidade do Flynow o caso de um Usuário se utilizar do cadastro de outro Usuário.'
  },
  's4.p3': {
    en: 'Flynow reserves the right to include, exclude or change the content and functionality of the application, as well as temporarily suspend or cancel it, at any time, regardless of prior notice to the User. Likewise, you can modify these Terms of Use, the most recent version of which will always be available for consultation in the application itself.',
    pt: 'O Flynow se reserva o direito de incluir, excluir ou alterar os conteúdos e funcionalidades do aplicativo, bem como suspendê-lo temporariamente ou cancelá-lo, a qualquer momento, independentemente de aviso prévio ao Usuário. Da mesma forma, poderá modificar estes Termos de Uso, cuja versão mais recente estará sempre disponível para consulta no próprio aplicativo.'
  },
  's4.p4': {
    en: "The user agrees that he will not use Flynow's features in order to circumvent the application's gamification system. That is, do not add and complete different activities just to earn the rewards of gamification.",
    pt: 'O usuário concorda que não utilizará as funcionalidades do Flynow de forma a burlar o sistema de gamificação do aplicativo. Ou seja, não adicionar e concluir diversas atividades somente para ganhar as recompensas da gamificação. '
  },
  's4.p5': {
    en: "The user is aware that he can be removed from the platform if he does not comply with any of the terms of use.",
    pt: 'O usuário está ciente que pode ser removido da plataforma caso não cumpra qualquer um dos termos de uso.'
  },
}

const TermsOfUse = () => {
  const {language,setLanguage} = useLanguage();
  return(
  <Layout>
    <SEO title="Termos de Uso" />
    <br/>
    <Row className="column-in-mobile" style={{justifyContent:'space-between'}}> 
      <h1 style={{marginBottom:10}}>{text['title'][language]}</h1>
      <Row style={{alignItems:'center'}}>
        <h5 style={{marginRight:10}}>Language:</h5>
        <button onClick={()=> setLanguage('en')} style={{border:'none', padding:'5px 10px',marginRight:10,background: language === 'en' ? colors.degrade : '#aaa', color: 'white',cursor:'pointer'}}><h5>EN</h5></button>
        <button onClick={()=> setLanguage('pt')} style={{border:'none', padding:'5px 10px',marginRight:10,background: language === 'pt' ? colors.degrade : '#aaa', color: 'white',cursor:'pointer'}}><h5>PT</h5></button>
      </Row>
    </Row>
    <br/>
    <br/>
    <h3>{text['s1'][language]}</h3>
    <Paragraph>
    {text['s1.p1'][language]}
      <br/>
      {text['s1.p2'][language]}    
    </Paragraph>
    <br/>
    
    <h3>{text['s2'][language]}</h3>
    <Paragraph>{text['s2.p1'][language]}</Paragraph>
    
    <br/>
    <h3>{text['s3'][language]}</h3>
    <Paragraph>{text['s3.p1'][language]}</Paragraph>
    <Paragraph>{text['s3.p2'][language]}</Paragraph>

    <br/>
    <h3>{text['s4'][language]}</h3>
    <Paragraph>{text['s4.p1'][language]}</Paragraph>
    <Paragraph>{text['s4.p2'][language]}</Paragraph>
    <Paragraph>{text['s4.p3'][language]}</Paragraph>
    <Paragraph>{text['s4.p4'][language]}</Paragraph>
    <Paragraph>{text['s4.p5'][language]}</Paragraph>
  </Layout>
)}

export default TermsOfUse
